<template lang="pug">
  .page-login
    header.page-login__header.app-header
      .app-header__wrapper
        .app-header__logo
          a(href='/').app-header__logo-link PROFEED PLANT
        .app-header__profile-wrap
          v-dropdown(
                ref="dropdown"
                width-auto
                position-right)
                v-btn(icon)
                  font-awesome-icon(
                    size="xs"
                    icon="ellipsis-v")
                v-card(
                  elevated
                  slot="content")
                    .app-settings
                      h3.app-settings__title {{ 'base.language' | translate }}
                      .app-settings__section
                        lang-switcher

    .page-login__auth
      .page-login__auth-wrap
        h2.page-login__auth-title PROFEED PLANT
        p.page-login__auth-desc {{ 'pages.login.title' | translate }}
        form.page-login__auth-form(@submit.prevent="signIn")
          .page-login__auth-group
            input(
              type="text"
              placeholder="Login"
              v-model="email").input-field
          .page-login__auth-group
            input(
              type='password'
              :placeholder="$t('base.password')"
              v-model="password").input-field
          v-btn.page-login__auth-btn(
            :loading="loading") {{ 'actions.login' | translate }}
          transition(name="fadex")
            .page-login__auth-alert(v-if="error")
              i.page-login__auth-icon.i-error
              p.page-login__auth-error {{ error }}
        a(href='#').page-login__auth-help(
          @click="showForgotPassword = true"
        ) {{ 'actions.forgot_password' | translate }}
    .page-login__promo
      h1.page-login__promo-title {{ 'pages.login.intro' | translate }}
      p.page-login__promo-desc {{ 'pages.login.description' | translate }}
      //- button.btn.btn--brand.btn--big Керувати фермою
    footer.page-login__footer
      p.page-login__footer-copy © Profeed Plant 2022

    v-dialog(
        max-width="350"
        v-model="showForgotPassword")
        forgot-password(@close="showForgotPassword = false")

</template>

<script>
import { mapActions } from 'vuex'
import LangSwitcher from './../components/layouts/header/app-settings/LangSwitcher'
import ForgotPassword from './ForgotPasswordModal.vue'
import { getRouteRedirect } from '@/util/utils'
import roles from '@/util/roles'

export default {
  name: 'Login',

  components: {
    LangSwitcher,
    ForgotPassword
  },

  data: () => ({
    showForgotPassword: false,
    email: process.env.VUE_APP_LOGIN,
    password: process.env.VUE_APP_PASSWORD,
    error: null,
    loading: false
  }),

  methods: {
    ...mapActions(['login']),
    async signIn () {
      const payload = {
        email: this.email,
        password: this.password
      }
      this.loading = true
      const { data, error } = await this.$store.dispatch('login', payload)
      this.loading = false
      if (!error) {
        this.error = ''
        if (data.user.role !== roles.employee) {
          await this.$router.push(this.redirectPath())
        }
      } else {
        this.error = error.error
      }
    },

    redirectPath () {
      if (this.$store.state.auth.user.user.need_change_pwd) {
        return '/change-password'
      }

      const currentFarm = localStorage.getItem('current_farm')
      // For single farm get it and redirect to another page
      if (!currentFarm && this.$store.state.auth.user.user.farm_count === 1) {
        const firstFarm = this.$store.state.auth.user.user.farms[0]
        this.$store.dispatch('setCurrentFarm', firstFarm.id)

        return getRouteRedirect(this.$store.state.auth.user.user.role, '/farm')
      }

      // When user has 1+ farm go to page for select one
      // or if user hasn't farm go to select page to show message about not exists farm
      return '/select-farm'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .app-settings {
    padding: 2rem;
    min-width: 19rem;

    &__title {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 500;
      color: $color-gray;
      margin-bottom: 1.5rem;
    }

    &__section {
      position: relative;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $color-gray-light-2;
    }

    &__btn-icon {
      font-size: 1.8rem;
      margin-right: 1.3rem;
    }

    &__btn-text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
</style>
